define("mvb-ember-components/components/controls/-button", ["exports", "mvb-ember-components/templates/components/controls/-button"], function (_exports, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _button.default,
    classNameBindings: ['hasToolTip:hasToolTip'],
    hasToolTip: false,
    enabled: true,
    iconOnly: false,
    mouseEnter: function mouseEnter() {
      if (this.get('onhover')) {
        this.get('onhover')();
      }
    },
    mouseMove: function mouseMove() {
      if (this.get('onhover')) {
        this.get('onhover')();
      }
    },
    actions: {
      onclick: function onclick() {
        if (this.get('enabled')) {
          this.sendAction('onclick');
        }
      }
    }
  });

  _exports.default = _default;
});