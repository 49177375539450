define("mvb-ember-components/components/controls/-power-select", ["exports", "ember-power-select/components/power-select", "ember-power-select/utils/computed-fallback-if-undefined"], function (_exports, _powerSelect, _computedFallbackIfUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE controls.-selects__single:notes
   With the given option for system-wide configs, we have the opportunity to set some default settings for all the single selects out there in our app.
   Only those selects which differ from the default settings have to set more options while adding a select to the page.
  
   You can change and/or enchance those settings in /app/components/power-select.js
   END-FREESTYLE-USAGE*/
  var _default = _powerSelect.default.extend({
    intl: Ember.inject.service(),

    /* BEGIN-FREESTYLE-USAGE controls.-selects__single */
    triggerComponent: (0, _computedFallbackIfUndefined.default)('controls/-power-select-custom-trigger'),
    searchEnabled: false,
    placeholder: Ember.computed(function () {
      return this.get('intl').t('label.selection.placeholder');
    }),
    noMatchesMessage: Ember.computed(function () {
      return this.get('intl').t('label.selection.noMatchesFound');
    }),
    searchMessage: Ember.computed(function () {
      return this.get('intl').t('label.selection.searchMessage');
    }),
    loadingMessage: Ember.computed(function () {
      return this.get('intl').t('label.selection.loadingMessage');
    }),

    /* END-FREESTYLE-USAGE*/
    valueProperty: null,
    actions: {
      select: function select(selected
      /*, e */
      ) {
        if (this.publicAPI.selected !== selected) {
          var valueProperty = this.get('valueProperty');

          if (valueProperty) {
            if (Ember.isArray(selected)) {
              for (var i = 0; i < selected.length; i++) {
                if (Ember.typeOf(selected[i]) === 'object') {
                  selected[i] = selected[i][valueProperty];
                }
              }
            } else if (Ember.typeOf(selected) === 'object') {
              selected = selected[valueProperty];
            }
          }

          this._super(selected);
        }
      }
    },
    updateSelection: function updateSelection(selection) {
      var valueProperty = this.get('valueProperty');
      var options = this.get('options');

      if (valueProperty && options && Ember.typeOf(options[0]) === 'object') {
        if (Ember.isArray) {
          for (var i = 0; i < selection.length; i++) {
            if (Ember.typeOf(selection[i]) !== 'object') {
              selection[i] = options.findBy(valueProperty, selection[i]);
            }
          }
        } else if (Ember.typeOf(selection) !== 'object') {
          selection = options.findBy(valueProperty, selection);
        }
      }

      this._super(selection);
    }
  });

  _exports.default = _default;
});