define("mvb-ember-components/components/modals/-modal-simple", ["exports", "ember-modal-dialog/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalDialog.default.extend({
    wrapperClassNames: 'modal-simple-wrapper',
    containerClassNames: 'modal-simple',
    destinationElementId: 'modal-overlays',
    targetAttachment: 'none'
  });

  _exports.default = _default;
});