define("mvb-ember-components/components/controls/-checkbox", ["exports", "mvb-ember-components/templates/components/controls/-checkbox"], function (_exports, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE controls.-checkbox:notes
   For styling purposes we have to use ```classNameBindings``` property. It will render some additional classes to the outer wrapper of the element according to the following set properties:
   * inline: inline if true the radio button gets the class ```.radio-inline``` otherwise it will be rendered with the class ```.radio```
   * styleClass: if set those classes will be added to the element
   * disabled: if true the class ```.disabled``` will be added to the element
   END-FREESTYLE-USAGE*/
  var _default = Ember.Component.extend({
    layout: _checkbox.default,

    /* BEGIN-FREESTYLE-USAGE controls.-checkbox */
    classNameBindings: ['inline:checkbox-inline:checkbox', 'styleClass', 'disabled:disabled'],

    /* END-FREESTYLE-USAGE */
    actions: {
      onchange: function onchange(value) {
        this.sendAction('onchange', value);
      },
      stopPropagation: function stopPropagation(event) {
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});