define("mvb-ember-components/components/controls/-collapsible", ["exports", "mvb-ember-components/templates/components/controls/-collapsible"], function (_exports, _collapsible) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _collapsible.default,
    intl: Ember.inject.service(),
    showMoreLabel: Ember.computed(function () {
      return this.get('intl').t('collapsible.showMoreLabel');
    }),
    showLessLabel: Ember.computed(function () {
      return this.get('intl').t('collapsible.showLessLabel');
    }),
    visible: false,
    visibleObserver: Ember.observer('visible', function () {
      if (this.get('visible')) {
        this.expandSection(this);
      } else {
        this.collapseSection(this);
      }
    }),
    sectionHeight: null,
    scrollHeight: null,
    currentLabel: Ember.computed('visible', 'showLessLabel', 'showMoreLabel', function () {
      return this.get('visible') ? this.get('showLessLabel') : this.get('showMoreLabel');
    }),
    didRender: function didRender() {
      this.set('scrollHeight', this.$('.collapsible_content').prop('scrollHeight'));
    },
    actions: {
      toggleCollapse: function toggleCollapse() {
        this.set('visible', !this.get('visible'));
      }
    },
    collapseSection: function collapseSection() {
      this.setSectionHeight(this.get('scrollHeight') + 'px');
      Ember.run.later(this, function () {
        // delay execution to ensure both statements are applied
        this.setSectionHeight(null);
      }, 25);
    },
    expandSection: function expandSection() {
      var _this = this;

      // remove fixed height after css transition finished
      this.$('.collapsible_content').one('transitionend', function () {
        if (_this.get('sectionHeight')) {
          // only when height is not null (to prevent expansion of collapsed contents)
          _this.setSectionHeight('auto');
        }
      }); // get the height of the element's inner content, regardless of its actual size
      // when the scrollHeight was not previously set, determine it now

      var scrollHeight = this.get('scrollHeight') ? this.get('scrollHeight') : this.$('.collapsible_content').prop('scrollHeight');
      this.setSectionHeight(scrollHeight + 'px');
    },
    setSectionHeight: function setSectionHeight(height) {
      if (height) {
        this.set('sectionHeight', 'height: ' + height);
      } else {
        this.set('sectionHeight', null);
      }
    }
  });

  _exports.default = _default;
});