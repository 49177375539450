define("mvb-ember-components/helpers/is-lt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isLt = isLt;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.is-lt:notes
   Returns true if the first params is less than the second one, false if otherwiese.
   END-FREESTYLE-USAGE */
  function isLt(params
  /*, hash*/
  ) {
    return params[0] < params[1];
  }

  var _default = Ember.Helper.helper(isLt);

  _exports.default = _default;
});