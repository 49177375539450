define("mvb-ember-components/helpers/gt-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gtEq = gtEq;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.gt-eq:notes
  The greater-equals function returns true when the first number is higher than or equal to the second number.
  END-FREESTYLE-USAGE */
  function gtEq(params
  /*, hash*/
  ) {
    return params[0] >= params[1];
  }

  var _default = Ember.Helper.helper(gtEq);

  _exports.default = _default;
});