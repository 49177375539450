define("mvb-ember-components/helpers/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.price = price;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.price:notes
   Formats the price.
  
   * params[0]: price
   * params[1]: delimiter, defaults to ','
   END-FREESTYLE-USAGE */
  function price(params
  /*, hash*/
  ) {
    if (Ember.isEmpty(params[0])) {
      return '';
    }

    var price = params[0].toString().split('.');
    var number = price[0];
    var decimal = price[1];
    var delimiter = params[1] ? params[1] : ',';

    if (decimal === undefined) {
      decimal = '00';
    } else if (decimal.length === 1) {
      decimal += '0';
    }

    return number + delimiter + decimal;
  }

  var _default = Ember.Helper.helper(price);

  _exports.default = _default;
});