define("mvb-ember-components/components/controls/-power-select-multiple-custom-trigger", ["exports", "ember-power-select/components/power-select-multiple/trigger", "mvb-ember-components/templates/components/controls/-power-select-multiple-custom-trigger"], function (_exports, _trigger, _powerSelectMultipleCustomTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trigger.default.extend({
    layout: _powerSelectMultipleCustomTrigger.default,
    showTrashcan: Ember.computed('select', function () {
      return this.get('select.selected.length') > 1;
    }),
    fontawesome: Ember.computed('extra', function () {
      return this.get('extra.firstObject.fontawesome');
    }),
    //Actions
    actions: {
      onMouseDown: function onMouseDown(e) {
        // stop the propagation so you can
        e.stopPropagation();
      },
      removeAllSelected: function removeAllSelected() {
        this.get('select').actions.select([]);
      }
    }
  });

  _exports.default = _default;
});