define("mvb-ember-components/helpers/subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subtract = subtract;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.subtract:notes
  Subtracts all the params to one value. The first parameter should be the one where the other params are subtracted from.
  END-FREESTYLE-USAGE */
  function subtract(params) {
    return params.reduce(function (a, b) {
      return a - b;
    });
  }

  var _default = Ember.Helper.helper(subtract);

  _exports.default = _default;
});