define("mvb-ember-components/components/controls/-accordion-panel", ["exports", "mvb-ember-components/templates/components/controls/-accordion-panel"], function (_exports, _accordionPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _accordionPanel.default,
    actions: {
      linkToHelp: function linkToHelp(helplink) {
        window.open(helplink, '_blank');
      }
    }
  });

  _exports.default = _default;
});