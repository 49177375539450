define("mvb-ember-components/components/tables/-action-bar-button", ["exports", "mvb-ember-components/templates/components/tables/-action-bar-button"], function (_exports, _actionBarButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actionBarButton.default,
    tagName: 'li',
    classNames: ['action_icon'],
    disabled: false,
    enabled: true,
    didInsertElement: function didInsertElement() {
      this.checkToEnable();
    },
    enabledPropertiesChanged: Ember.observer('disabled', 'selectedData', 'enabledCheckAction', function () {
      this.checkToEnable();
    }),
    checkToEnable: function checkToEnable() {
      var enabled = true;
      var disabled = this.get('disabled');

      if (disabled) {
        enabled = false;
      } else {
        var enabledCheckAction = this.get('enabledCheckAction');

        if (enabledCheckAction) {
          var selectedData = this.get('selectedData');
          enabled = enabledCheckAction(selectedData);
        }
      }

      this.set('enabled', enabled);
    },
    actions: {
      buttonPressed: function buttonPressed() {
        this.get('action')();
      }
    }
  });

  _exports.default = _default;
});