define("mvb-ember-components/components/controls/-datepicker", ["exports", "moment", "mvb-ember-components/templates/components/controls/-datepicker"], function (_exports, _moment, _datepicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _datepicker.default,
    classNameBindings: ['hasToolTip:hasToolTip'],
    formControlStyle: Ember.computed('hasToolTip', function () {
      var style = 'form-control';

      if (this.get('hasToolTip')) {
        style += ' toolTip';
      }

      return style;
    }),
    intl: Ember.inject.service(),
    placeholder: Ember.computed(function () {
      return this.get('intl').t('label.placeholder.date');
    }),
    yearRange: '10',
    actions: {
      toggleDatepicker: function toggleDatepicker() {
        this.toggleProperty('showDatepicker');
      },
      onSelection: function onSelection(date) {
        this.sendAction('onSelection', date);
        this.set('showDatepicker', false);
      },
      onOpen: function onOpen() {
        this.sendAction('onOpen');
      },
      onClose: function onClose() {
        this.sendAction('onClose');
      },
      onDraw: function onDraw() {
        this.sendAction('onDraw');
      },
      onFocusOut: function onFocusOut(event) {
        var element = event.target;

        if (!Ember.isEmpty(element)) {
          var valueLength = element.value.length;
          var date = element.value;

          if (valueLength > 4) {
            var currentFormat = valueLength < 8 ? [this.get('intl').t('config.datepicker.format.dayFirst.short'), this.get('intl').t('config.datepicker.format.yearFirst.withSeparator.short')] // jshint ignore:line
            : [this.get('intl').t('config.datepicker.format.dayFirst.long'), this.get('intl').t('config.datepicker.format.yearFirst.withSeparator.long')];
            var format = valueLength < 8 ? this.get('intl').t('config.datepicker.format.fullDate.short') : this.get('intl').t('config.datepicker.format.fullDate.long');
            date = (0, _moment.default)(element.value, currentFormat).format(format);
          }

          this.sendAction('onSelection', date);
        }

        this.set('showDatepicker', false);
      }
    }
  });

  _exports.default = _default;
});