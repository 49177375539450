define("mvb-ember-components/components/controls/-power-select-multiple", ["exports", "ember-power-select/components/power-select-multiple", "ember-power-select/utils/computed-fallback-if-undefined"], function (_exports, _powerSelectMultiple, _computedFallbackIfUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE controls.-selects__multi:notes
   power-select-multiple has some issues with placeholders when the search field is disabled. It is a known issue and they are working on it.
  
   With the given option for system-wide configs, we have the opportunity to set some default settings for all the multiple selects out there in our app.
   Only those selects which differ from the default settings have to set more options while adding a select to the page.
  
   We added a trigger FallBack which shows a Trashcan-Icon to remove all selected options
  
   You can change and/or enhance those settings in /app/components/power-select-multiple.js
   END-FREESTYLE-USAGE*/
  var _default = _powerSelectMultiple.default.extend({
    intl: Ember.inject.service(),

    /* BEGIN-FREESTYLE-USAGE controls.-selects__multi */
    triggerComponent: (0, _computedFallbackIfUndefined.default)('controls/-power-select-multiple-remove-trigger'),
    searchEnabled: true,
    placeholder: Ember.computed(function () {
      return this.get('intl').t('label.selection.placeholder.multiple');
    }),
    noMatchesMessage: Ember.computed(function () {
      return this.get('intl').t('label.selection.noMatchesFound');
    }),
    searchMessage: Ember.computed(function () {
      return this.get('intl').t('label.selection.searchMessage');
    }),
    loadingMessage: Ember.computed(function () {
      return this.get('intl').t('label.selection.loadingMessage');
    })
    /* END-FREESTYLE-USAGE */

  });

  _exports.default = _default;
});