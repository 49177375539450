define("mvb-ember-components/components/widgets/-thumbnail", ["exports", "mvb-ember-components/templates/components/widgets/-thumbnail"], function (_exports, _thumbnail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _thumbnail.default,
    actions: {
      onclick: function onclick() {
        this.sendAction('onclick');
      }
    }
  });

  _exports.default = _default;
});