define("mvb-ember-components/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sum = sum;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.sum:notes
  Adds all the params to one value. The order of the params does not matter.
  END-FREESTYLE-USAGE */
  function sum(params) {
    return params.reduce(function (a, b) {
      return a + b;
    });
  }

  var _default = Ember.Helper.helper(sum);

  _exports.default = _default;
});