define("ember-cli-app-version/initializer-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initializerFactory;
  var libraries = Ember.libraries;

  function initializerFactory(name, version) {
    var registered = false;
    return function () {
      if (!registered && name && version) {
        libraries.register(name, version);
        registered = true;
      }
    };
  }
});