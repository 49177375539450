define("mvb-ember-components/components/controls/-radio", ["exports", "mvb-ember-components/templates/components/controls/-radio"], function (_exports, _radio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE controls.-radio:notes
   For styling purposes we have to use ```classNameBindings``` property. It will render some additional classes to the outer wrapper of the element according to the following set properties:
   * inline: inline if true the radio button gets the class ```.radio-inline``` otherwise it will be rendered with the class ```.radio```
   * styleClass: if set those classes will be added to the element
   * disabled: if true the class ```.disabled``` will be added to the element
   * hasToolTip: if true the class ```.hasToolTip``` will be added to the element
   END-FREESTYLE-USAGE*/
  var _default = Ember.Component.extend({
    layout: _radio.default,

    /* BEGIN-FREESTYLE-USAGE controls.-radio */
    classNameBindings: ['inline:radio-inline:radio', 'styleClass', 'disabled:disabled', 'hasToolTip:hasToolTip'],

    /* END-FREESTYLE-USAGE */
    didRender: function didRender() {
      // Required for IE11 to correctly report its value https://github.com/yapplabs/ember-radio-button/pull/82
      var value = this.get('value');

      if (value) {
        this.element.querySelector('input').value = value;
      }
    },
    actions: {
      onchange: function onchange(value) {
        this.sendAction('onchange', value);
      }
    }
  });

  _exports.default = _default;
});