define("mvb-ember-components/components/controls/-pikaday-input", ["exports", "moment", "ember-pikaday/components/pikaday-input"], function (_exports, _moment, _pikadayInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pikadayInput.default.extend({
    classNames: ['datepicker-input'],
    onPikadayOpen: function onPikadayOpen() {
      // checks if a minDate is set and if this minDate is in the future
      // if so the calendar will open on the minDate month/year
      var minDate = this.get('minDate');

      if (!Ember.isEmpty(minDate) && (0, _moment.default)(minDate).isAfter((0, _moment.default)())) {
        var minYear = (0, _moment.default)(minDate).get('year');
        var minMonth = (0, _moment.default)(minDate).get('month');
        this.get('pikaday').gotoDate(new Date(minYear, minMonth));
      } // after that call the default behavior


      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});