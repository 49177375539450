define("mvb-ember-components/components/product/-icon", ["exports", "mvb-ember-components/templates/components/product/-icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var glyphicons = {
    audio: 'glyphicons glyphicons-music',
    book: 'glyphicons glyphicons-book',
    calendar: 'glyphicons glyphicons-calendar',
    cartographic: 'glyphicons glyphicons-globe',
    'dl-products': 'glyphicons glyphicons-cd',
    ebook: 'glyphicons glyphicons-ipad',
    hardware: 'glyphicons glyphicons-ipad',
    journal: 'glyphicons glyphicons-book-open',
    magazine: 'glyphicons glyphicons-book-open',
    'media-packages': 'glyphicons glyphicons-book',
    movie: 'glyphicons glyphicons-film',
    'non-book': 'glyphicons glyphicons-dice-3',
    'retailer-material': 'glyphicons glyphicons-flag',
    stationery: 'glyphicons glyphicons-pencil',
    undefined: 'glyphicons glyphicons-more'
  };
  var fontawesome = {
    alicorn: {
      icon: 'alicorn',
      prefix: 'fas'
    },
    audio: {
      icon: 'headphones',
      prefix: 'fas'
    },
    baby: {
      icon: 'baby',
      prefix: 'fas'
    },
    book: {
      icon: 'book',
      prefix: 'fas'
    },
    calendar: {
      icon: 'calendar-alt',
      prefix: 'far'
    },
    cartographic: {
      icon: 'globe',
      prefix: 'fas'
    },
    chess: {
      icon: 'chess',
      prefix: 'fas'
    },
    'dl-products': {
      icon: 'cloud-download',
      prefix: 'fas'
    },
    ebook: {
      icon: 'tablet-alt',
      prefix: 'fas'
    },
    futbol: {
      icon: 'futbol',
      prefix: 'fas'
    },
    gifts: {
      icon: 'gifts',
      prefix: 'fas'
    },
    hardware: {
      icon: 'tablet-alt',
      prefix: 'fas'
    },
    journal: {
      icon: 'newspaper',
      prefix: 'fas'
    },
    licence: {
      icon: 'key',
      prefix: 'fas'
    },
    magazine: {
      icon: 'newspaper',
      prefix: 'fas'
    },
    'media-packages': {
      icon: 'books-medical',
      prefix: 'fas'
    },
    movie: {
      icon: 'film',
      prefix: 'fas'
    },
    'puzzle-piece': {
      icon: 'puzzle-piece',
      prefix: 'fas'
    },
    'retailer-material': {
      icon: 'flag',
      prefix: 'far'
    },
    stationery: {
      icon: 'file',
      prefix: 'far'
    },
    undefined: {
      icon: 'ellipsis-h',
      prefix: 'fas'
    }
  };

  var _default = Ember.Component.extend({
    layout: _icon.default,
    intl: Ember.inject.service(),
    tagName: '',
    title: Ember.computed('productFormId', function () {
      var label = this.get('intl').t('label.form.product.list.productFormId.' + this.get('productFormId'));

      if (label.startsWith('label') && this.get('productFormId')) {
        label = this.get('intl').t('cl_150_' + this.get('productFormId'));
      }

      if (label.startsWith('label')) {
        label = '';
      }

      return label;
    }),
    iconClass: Ember.computed('productIcon', function () {
      var productIcon = this.get('productIcon') || 'undefined';
      return "product_icon ".concat(glyphicons[productIcon]);
    }),
    faIcon: Ember.computed('productIcon', function () {
      var productIcon = this.get('productIcon') || 'undefined';
      return fontawesome[productIcon];
    })
  });

  _exports.default = _default;
});