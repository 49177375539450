define('ember-block-slots/helpers/block-params', ['exports', 'ember'], function (exports, _ember) {
  exports.blockParams = blockParams;
  var A = _ember['default'].A;
  var Helper = _ember['default'].Helper;

  /**
   * Per yield slot parameters made available to an associated block slot
   *
   * e.g.
   *
   * {{#yield-slot 'foo' (block-params 'a' 'b' 'c')}}
   *   <div>{{yield}}</div>
   * {{/yield-slot}}
   *
   * would be available as
   *
   * {{#block-slot 'foo' as |x y z|}}
   *   {{x}} {{y}} {{z}}
   * {{/block-slot}}
   *
   * and would result in DOM
   *
   * <div>a b c</div>
   */

  function blockParams(params) {
    return A(params.slice());
  }

  exports['default'] = Helper.helper(blockParams);
});
/* eslint valid-jsdoc:0 */