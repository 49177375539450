define("mvb-ember-components/helpers/hash-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hashSimple = hashSimple;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.hash-simple:notes
   Gets around the issue mentioned at https://github.com/emberjs/ember.js/issues/14738.
   Hash helper was used in the data-table plugin
  END-FREESTYLE-USAGE */
  function hashSimple(params, hash) {
    return Object.assign({}, hash);
  }

  var _default = Ember.Helper.helper(hashSimple);

  _exports.default = _default;
});