define("mvb-ember-components/components/product/-availability", ["exports", "mvb-ember-components/templates/components/product/-availability"], function (_exports, _availability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _availability.default,
    tagName: 'span',
    showTooltip: true,
    title: Ember.computed('active', 'feedAvailability', 'showTooltip', function () {
      if (!this.get('showTooltip')) {
        return null;
      }

      if (this.get('active')) {
        return 'label.form.productTracker.list.feedAvailability.' + this.get('feedAvailability');
      } else {
        return 'label.form.productTracker.list.feedAvailability.deactivated';
      }
    }),
    iconClass: Ember.computed('active', 'feedAvailability', function () {
      if (this.get('active')) {
        var iconClass = '';

        switch (this.get('feedAvailability')) {
          case '01':
            iconClass = 'available print-available';
            break;

          default:
          case '02':
            break;

          case '03':
            iconClass = 'semi-available print-semi-available';
            break;

          case '04':
            iconClass = 'not-available print-not-available';
            break;
        }

        return 'status status-availability ' + iconClass;
      } else {
        return 'status status-availability inactive';
      }
    })
  });

  _exports.default = _default;
});