define('ember-block-slots/components/block-slot', ['exports', 'ember', 'ember-block-slots/templates/components/block-slot', 'ember-prop-types', 'ember-block-slots/mixins/slots', 'ember-block-slots/components/yield-slot'], function (exports, _ember, _emberBlockSlotsTemplatesComponentsBlockSlot, _emberPropTypes, _emberBlockSlotsMixinsSlots, _emberBlockSlotsComponentsYieldSlot) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var defineProperty = _ember['default'].defineProperty;
  var isPresent = _ember['default'].isPresent;
  var on = _ember['default'].on;

  /**
   * A block slot provides content for a target yield slot with a matching name
   *
   * e.g. {{#block-slot 'foo'}} would provide content for {{#yield-slot 'foo'}}
   *
   * Block slots may also use block params, see addon/helpers/block-params.js
   */
  var BlockSlot = Component.extend({

    // == Component properties ==================================================

    layout: _emberBlockSlotsTemplatesComponentsBlockSlot['default'],
    tagName: '',

    // == State properties ======================================================

    propTypes: {
      isTargetSlotYielding: _emberPropTypes.PropTypes.bool,
      // TODO better validation message
      // https://github.com/ciena-blueplanet/ember-prop-types/issues/15
      _name: _emberPropTypes.PropTypes.string.isRequired,
      _yieldSlot: _emberPropTypes.PropTypes.EmberObject
    },

    // == Events ================================================================

    _didInsertElement: on('didInsertElement', function () {
      var _this = this;

      // Active the yield slot using the slots interface
      var slottedComponent = this.nearestOfType(_emberBlockSlotsMixinsSlots['default']);
      if (!slottedComponent._isRegistered(this._name)) {
        slottedComponent._activateSlot(this._name);
        // Store the slotted component for use during deactivation
        this.set('slottedComponent', slottedComponent);
        // Activation is done, the next pass will contain a local yieldSlot
        return;
      }

      // Find the yield slot for this block
      var yieldSlot = this.nearestOfType(_emberBlockSlotsComponentsYieldSlot['default']);

      if (yieldSlot) {
        // Store the activated yield slot for block params computed properties
        this.set('_yieldSlot', yieldSlot);

        // The slotted component will yield multiple times - once to register
        // the activate slots and once more per active slot - only display this
        // block when its associated slot is the one yielding
        var isTargetSlotYielding = yieldSlot._name === this._name;
        this.set('isTargetSlotYielding', isTargetSlotYielding);

        // If the associated slot has block params, create a computed property
        // for each block param.  Technically this could be an unlimited, but
        // hbs lacks a spread operator so params are currently limited to 9
        // (see the yield in the block-slot template)
        //
        // Spread PR: https://github.com/wycats/handlebars.js/pull/1149
        if (isTargetSlotYielding && isPresent(yieldSlot._blockParams)) {
          // p0 p1 p2...
          yieldSlot._blockParams.forEach(function (param, index) {
            defineProperty(_this, 'p' + index, computed.readOnly('_yieldSlot._blockParams.' + index));
          });
        }
      }
    }),

    _willDestroyElement: on('willDestroyElement', function () {
      var slottedComponent = this.get('slottedComponent');
      if (slottedComponent) {
        // Deactivate the yield slot using the slots interface when the block
        // is destroyed to allow the yield slot default {{else}} to take effect
        // dynamically
        slottedComponent._deactivateSlot(this._name);
      }
    })
  });

  BlockSlot.reopenClass({
    positionalParams: ['_name']
  });

  exports['default'] = BlockSlot;
});