define("mvb-ember-components/helpers/to-lower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toLower = toLower;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.to-lower:notes
  Transforms any String to lower case.
  END-FREESTYLE-USAGE */
  function toLower(params
  /*, hash*/
  ) {
    if (params[0] && typeof params[0] === 'string') {
      return params[0].toLowerCase();
    }

    return '';
  }

  var _default = Ember.Helper.helper(toLower);

  _exports.default = _default;
});