define("mvb-ember-components/helpers/nvl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nvl = nvl;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.nvl:notes
  The nvl function is similar to the Oracle NVL function: It returns from a list of parameters the first non-null and non-empty value.
  
  Example:
  (nvl inputValue 'n/a') returns the value of inputValue if it is not null and 'n/a' otherwise.
  
  END-FREESTYLE-USAGE */
  function nvl(params
  /* , hash */
  ) {
    for (var i = 0; i < params.length; i++) {
      if (params[i] && params[i] !== '') {
        return params[i];
      }
    }

    return null;
  }

  var _default = Ember.Helper.helper(nvl);

  _exports.default = _default;
});