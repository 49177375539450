define('ember-prop-types/utils/prop-types', ['exports', 'ember', 'ember-prop-types/utils/logger', 'ember-prop-types/utils/validators'], function (exports, _ember, _emberPropTypesUtilsLogger, _emberPropTypesUtilsValidators) {
  exports.getDef = getDef;
  exports.generateType = generateType;
  var isArray = _ember['default'].isArray;
  var typeOf = _ember['default'].typeOf;

  var PropTypes = {};

  function getDef(def) {
    // Support handling non-function call propTypes
    // i.e. PropTypes.string.isRequired
    if (def && def.prototype) {
      return {
        isRequired: def.isRequired,
        required: def.required,
        type: def.type
      };
    }

    // Support handling function call propTypes
    // i.e. PropTypes.string({required: true})
    return def;
  }

  function generateType(key) {
    return {
      isRequired: {
        required: true,
        type: key
      },
      required: false,
      type: key
    };
  }

  ;['any', 'array', 'bool', 'date', 'element', 'EmberComponent', 'EmberObject', 'func', 'null', 'number', 'object', 'string', 'symbol'].forEach(function (key) {
    PropTypes[key] = function (options) {
      var def = {
        required: false,
        type: key
      };

      if (typeOf(options) !== 'object') {
        return def;
      }

      if ('required' in options) {
        def.required = options.required;
      }

      if (options.updatable === false) {
        def.updatable = false;
      }

      return def;
    };

    var obj = PropTypes[key];

    obj.isRequired = {
      required: true,
      type: key
    };

    obj.required = false;
    obj.type = key;
  });

  PropTypes.arrayOf = function (typeDef, options) {
    var type = generateType('arrayOf');

    type.typeDef = getDef(typeDef);

    if (typeOf(options) !== 'object') {
      type.isRequired.typeDef = type.typeDef;
      return type;
    }

    delete type.isRequired;

    if ('required' in options) {
      type.required = options.required;
    }

    if (options.updatable === false) {
      type.updatable = false;
    }

    return type;
  };

  PropTypes.oneOfType = function (typeDefs, options) {
    var type = generateType('oneOfType');

    if (isArray(typeDefs)) {
      typeDefs = typeDefs.map(function (def) {
        return getDef(def);
      });
    }

    type.typeDefs = typeDefs;

    if (typeOf(options) !== 'object') {
      type.isRequired.typeDefs = type.typeDefs;
      return type;
    }

    delete type.isRequired;

    if ('required' in options) {
      type.required = options.required;
    }

    if (options.updatable === false) {
      type.updatable = false;
    }

    return type;
  };

  PropTypes.oneOf = function (valueOptions, options) {
    var type = generateType('oneOf');

    type.valueOptions = valueOptions;

    if (typeOf(options) !== 'object') {
      type.isRequired.valueOptions = type.valueOptions;
      return type;
    }

    delete type.isRequired;

    if ('required' in options) {
      type.required = options.required;
    }

    if (options.updatable === false) {
      type.updatable = false;
    }

    return type;
  };

  PropTypes.instanceOf = function (typeDef, options) {
    var type = generateType('instanceOf');

    type.typeDef = typeDef;

    if (typeOf(options) !== 'object') {
      type.isRequired.typeDef = type.typeDef;
      return type;
    }

    delete type.isRequired;

    if ('required' in options) {
      type.required = options.required;
    }

    if (options.updatable === false) {
      type.updatable = false;
    }

    return type;
  };

  PropTypes.shape = function (typeDefs, options) {
    var type = generateType('shape');

    if (typeOf(typeDefs) === 'object') {
      Object.keys(typeDefs).forEach(function (key) {
        typeDefs[key] = getDef(typeDefs[key]);
      });
    }

    type.typeDefs = typeDefs;

    if (typeOf(options) !== 'object') {
      type.isRequired.typeDefs = type.typeDefs;
      return type;
    }

    delete type.isRequired;

    if ('required' in options) {
      type.required = options.required;
    }

    if (options.updatable === false) {
      type.updatable = false;
    }

    return type;
  };

  exports['default'] = PropTypes;
  exports.validators = _emberPropTypesUtilsValidators['default'];
  exports.logger = _emberPropTypesUtilsLogger['default'];
});