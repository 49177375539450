define("mvb-ember-components/components/tables/-data-table", ["exports", "mvb-ember-components/mixins/paginated-data-support", "ember-block-slots", "mvb-ember-components/templates/components/tables/-data-table"], function (_exports, _paginatedDataSupport, _emberBlockSlots, _dataTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE tables.-data-table:notes
  The DataTable Component offers an easy way to display models in a table view.
  
  ##### Features
  
  Out of the box it brings
  * pagination
  * pageSize selection
  * sorting
  * ordering
  
  Therefore it uses the *PaginatedDataSupport* Mixin.
  
  ##### SearchFilter and reloading
  
  In order to set a filter from outside pass a property *searchFilter* to the dataTable.
  Every time the searchFilter property gets re-set the dataTable will reload.
  
  If the searchFilter has a property *keepPage* set to true, the dataTable will keep the current page,
   otherwise pageNumber will be set to page 1. The keepPage property will not be sent to the backend as a filterProperty.
  
  ##### Settings
  
  Here you find settings you can set with their default values in brackets.
  
   * _dataModel_: The name of the model to be used. Must be provided!
   * _tablesClasses_: css classes to be rendered on the resultList div
   * _pageSizes_ ([10,25,50,100]): array of pageSizes the user can select\
     set null if you do not wan't users to be able to select the page size
   * _pageSize_ (50): initial page size
   * _sortField_ ('id'): default sort Field
   * _showPagination_ (true): hides pagination if false
   * _showNumEntries_ (false): show number of entries at the first place (instead of action bar)
   * _showActionBar_ (true): hides action bar if false
   * _selectable_ (true): if true checkboxes for selectAll will be rendered
   * _headerData_ (null): if you want the table to render a header and provide sorting this value must be set\
     It must be set with an array of objects with following keys
     * label: the headers label
     * title: the headers title text
     * sortField: optional; if defined the column will be sortable
   * _loadOnInit_ (false): if true the table will load the data automatically on initialization
   * _sortSelectBox_ (false): if true sorting will be provided through a selectBox instead through column headers
   * _responsiveTable_ (false): if true the table will show responsive styling changes
   * _showMoreListItems_ (false): if true the table will show more List Items (max 10) in the header and footer the "col-sm-X" changed for list_actions (4/6), list_pagination (4/3) and list_pageSize (4/3)
   * _entryNameKey_ ('label.datatable.entries.numEntries'): Key to lookup text in translation for name of many entries. If no translation is available for the key no output of the number of entries is done. 
   * _entryNameOneKey_ (entryNameKey or 'label.datatable.entries.numEntries.single'): Key to lookup text in translation for name of one entry  
  
  
  END-FREESTYLE-USAGE */
  var _default = Ember.Component.extend(_paginatedDataSupport.default, _emberBlockSlots.default, {
    intl: Ember.inject.service(),
    layout: _dataTable.default,
    // default values
    pageSizes: ['10', '25', '50', '100'],
    // set null if you do not wan't users to select the page size
    showPagination: true,
    showActionBar: true,
    showNumEntries: false,
    selectable: true,
    headerData: null,
    loadOnInit: false,
    sortSelectBox: false,
    listSwitchType: 'cover',
    entryNameKey: '',
    entryNameOneKey: '',
    responsiveTable: false,
    showMoreListItems: false,
    deselectAllOnPageDataChange: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('loadOnInit') && !this.get('pageData')) {
        this.loadData();
      }
    },
    colSmBlockSizeListActions: Ember.computed('showMoreListItems', function () {
      if (this.get('showMoreListItems')) {
        return 6;
      } else {
        return 4;
      }
    }),
    colSmBlockSizeListPagination: Ember.computed('showMoreListItems', function () {
      if (this.get('showMoreListItems')) {
        return 3;
      } else {
        return 4;
      }
    }),
    colSmBlockSizeListPageSize: Ember.computed('showMoreListItems', function () {
      if (this.get('showMoreListItems')) {
        return 3;
      } else {
        return 4;
      }
    }),
    onPageDataChange: Ember.observer('pageData', function () {
      if (this.get('deselectAllOnPageDataChange')) {
        this.deselectAll();
        this.set('selection', {});
      }
    }),
    selectedSortOption: Ember.computed('headerData', 'sortField', 'sortSelectBox', function () {
      if (this.get('sortSelectBox')) {
        // set initial value for sort power select
        var filteredArray = this.get('headerData').filterBy('sortField', this.get('sortField'));

        if (filteredArray) {
          return filteredArray[0];
        }
      }

      return null;
    }),
    _onNewSearchFilter: Ember.observer('searchFilter', function () {
      if (this.get('searchFilter')) {
        if (this.get('searchFilter.keepPage')) {
          this.set('searchFilter.keepPage', undefined);
        } else {
          this.set('pageNumber', 1);
        }

        this.deselectAll();
        this.loadData();
      }
    }),
    _entryNameManyKey: Ember.computed('entryNameKey', function () {
      var entryNameKey = this.get('entryNameKey');

      if (!entryNameKey) {
        entryNameKey = 'label.datatable.entries.numEntries';
      }

      return entryNameKey;
    }),
    _entryNameOneKey: Ember.computed('entryNameOneKey', 'entryNameKey', function () {
      var entryNameOneKey = this.get('entryNameOneKey');

      if (!entryNameOneKey) {
        var entryNameKey = this.get('entryNameKey');

        if (entryNameKey) {
          entryNameOneKey = entryNameKey;
        } else {
          entryNameOneKey = 'label.datatable.entries.numEntries.single';
        }
      }

      return entryNameOneKey;
    }),
    _hideNumEntries: Ember.computed('_entryNameManyKey', function () {
      var entryNameKey = this.get('_entryNameManyKey');
      return !this.get('intl').exists(entryNameKey);
    }),
    getSearchFilter: function getSearchFilter() {
      return this.get('searchFilter');
    },
    _sortableFields: Ember.computed.filterBy('headerData', 'sortField'),
    _showPagination: Ember.computed('pageData.length', 'showPagination', function () {
      return this.get('pageData.length') > 0 && this.get('showPagination');
    }),
    _showPageSizes: Ember.computed('pageSizes', function () {
      return this.get('pageSizes');
    }),
    _showTopBar: Ember.computed('sortSelectBox', 'showNumEntries', 'selectable', 'showActionBar', '_showPagination', '_showPageSizes', 'showListSwitch', function () {
      return this.get('sortSelectBox') || this.get('showActionBar') || this.get('showNumEntries') || this.get('selectable') || this.get('_showPagination') || this.get('_showPageSizes') || this.get('showListSwitch');
    }),
    _showBottomBar: Ember.computed('selectable', 'showActionBar', '_showPagination', '_showPageSizes', 'showListSwitch', function () {
      return this.get('selectable') || this.get('showActionBar') || this.get('_showPagination') || this.get('_showPageSizes') || this.get('showListSwitch');
    }),
    _totalElements: Ember.computed('pageData.meta.{totalElements,totalResourceCount}', function () {
      var result = this.get('pageData.meta.totalElements');

      if (!result) {
        result = this.get('pageData.meta.totalResourceCount');
      }

      return result;
    }),
    _totalPages: Ember.computed('pageData.meta.totalPages', '_totalElements', 'pageSize', function () {
      var result = this.get('pageData.meta.totalPages');

      if (!result) {
        var totalElements = this.get('_totalElements');
        var pageSize = this.get('pageSize');

        if (totalElements && pageSize) {
          result = Math.floor((totalElements - 1) / pageSize) + 1;
        }
      }

      return result;
    }),
    actions: {
      switchList: function switchList(type) {
        this.set('listSwitchType', type);
      },
      refresh: function refresh() {
        this.loadData();
      }
    }
  });

  _exports.default = _default;
});