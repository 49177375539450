define("mvb-ember-components/helpers/divide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.divide = divide;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.divide:notes
  Performs a division.
  
   * params[0]: Dividend
   * params[1]: Divisor
  END-FREESTYLE-USAGE */
  function divide(params
  /*, hash*/
  ) {
    return params.reduce(function (a, b) {
      return a / b;
    });
  }

  var _default = Ember.Helper.helper(divide);

  _exports.default = _default;
});