define("mvb-ember-components/helpers/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;

  /*
   * BEGIN-FREESTYLE-USAGE helper.contains:notes
   * Returns true if the value (second param) is in the given array (first param), false if otherwise.
   * END-FREESTYLE-USAGE
   */
  function contains(params
  /*, hash*/
  ) {
    var array = params[0];
    var value = params[1];

    if (!Ember.isArray(array)) {
      array = Ember.A(); // jshint ignore:line
    }

    var wrappedArray = Ember.A(array); // jshint ignore:line

    return wrappedArray.includes(value);
  }

  var _default = Ember.Helper.helper(contains);

  _exports.default = _default;
});