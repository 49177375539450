define("mvb-ember-components/components/controls/-bs-tab", ["exports", "ember-bootstrap/components/base/bs-tab", "mvb-ember-components/components/controls/-bs-tab-pane", "mvb-ember-components/templates/components/controls/-bs-tab"], function (_exports, _bsTab, _bsTabPane, _bsTab2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsTab.default.extend({
    layout: _bsTab2.default,
    init: function init() {
      this._super.apply(this, arguments);
    },
    childPanes: Ember.computed.filter('children', function (view) {
      return view instanceof _bsTabPane.default;
    })
  });

  _exports.default = _default;
});