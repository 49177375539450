define("mvb-ember-components/components/controls/-form-group", ["exports", "mvb-ember-components/templates/components/controls/-form-group"], function (_exports, _formGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _formGroup.default,
    hasHint: false
  });

  _exports.default = _default;
});