define('ember-prop-types/utils/validators/index', ['exports', 'ember', 'ember-prop-types/utils/validators/any', 'ember-prop-types/utils/validators/array', 'ember-prop-types/utils/validators/array-of', 'ember-prop-types/utils/validators/bool', 'ember-prop-types/utils/validators/date', 'ember-prop-types/utils/validators/element', 'ember-prop-types/utils/validators/ember-component', 'ember-prop-types/utils/validators/ember-object', 'ember-prop-types/utils/validators/func', 'ember-prop-types/utils/validators/instance-of', 'ember-prop-types/utils/validators/null', 'ember-prop-types/utils/validators/number', 'ember-prop-types/utils/validators/object', 'ember-prop-types/utils/validators/one-of', 'ember-prop-types/utils/validators/one-of-type', 'ember-prop-types/utils/validators/shape', 'ember-prop-types/utils/validators/string', 'ember-prop-types/utils/validators/symbol'], function (exports, _ember, _emberPropTypesUtilsValidatorsAny, _emberPropTypesUtilsValidatorsArray, _emberPropTypesUtilsValidatorsArrayOf, _emberPropTypesUtilsValidatorsBool, _emberPropTypesUtilsValidatorsDate, _emberPropTypesUtilsValidatorsElement, _emberPropTypesUtilsValidatorsEmberComponent, _emberPropTypesUtilsValidatorsEmberObject, _emberPropTypesUtilsValidatorsFunc, _emberPropTypesUtilsValidatorsInstanceOf, _emberPropTypesUtilsValidatorsNull, _emberPropTypesUtilsValidatorsNumber, _emberPropTypesUtilsValidatorsObject, _emberPropTypesUtilsValidatorsOneOf, _emberPropTypesUtilsValidatorsOneOfType, _emberPropTypesUtilsValidatorsShape, _emberPropTypesUtilsValidatorsString, _emberPropTypesUtilsValidatorsSymbol) {
  // eslint-disable-line

  var assign = _ember['default'].assign;
  var merge = _ember['default'].merge;

  var objectAssign = Object.assign || assign || merge;

  var validators = {
    any: _emberPropTypesUtilsValidatorsAny['default'],
    array: _emberPropTypesUtilsValidatorsArray['default'],
    bool: _emberPropTypesUtilsValidatorsBool['default'],
    date: _emberPropTypesUtilsValidatorsDate['default'],
    element: _emberPropTypesUtilsValidatorsElement['default'],
    EmberComponent: _emberPropTypesUtilsValidatorsEmberComponent['default'],
    EmberObject: _emberPropTypesUtilsValidatorsEmberObject['default'],
    func: _emberPropTypesUtilsValidatorsFunc['default'],
    instanceOf: _emberPropTypesUtilsValidatorsInstanceOf['default'],
    'null': _emberPropTypesUtilsValidatorsNull['default'],
    number: _emberPropTypesUtilsValidatorsNumber['default'],
    object: _emberPropTypesUtilsValidatorsObject['default'],
    oneOf: _emberPropTypesUtilsValidatorsOneOf['default'],
    string: _emberPropTypesUtilsValidatorsString['default'],
    symbol: _emberPropTypesUtilsValidatorsSymbol['default']
  };

  objectAssign(validators, {
    arrayOf: _emberPropTypesUtilsValidatorsArrayOf['default'].bind(this, validators),
    oneOfType: _emberPropTypesUtilsValidatorsOneOfType['default'].bind(this, validators),
    shape: _emberPropTypesUtilsValidatorsShape['default'].bind(this, validators)
  });

  exports['default'] = validators;
});