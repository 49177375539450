define("mvb-ember-components/components/controls/-textfield", ["exports", "mvb-ember-components/templates/components/controls/-textfield"], function (_exports, _textfield) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COLLAPSIBLE_CONTENT_MIN_SIZE = 190;

  var _default = Ember.Component.extend({
    layout: _textfield.default,
    type: 'text',
    filterType: null,
    readOnly: false,
    isCollapsible: false,
    zeroToBlank: false,
    useCollapsible: Ember.computed('value.length', function () {
      return this.get('value.length') > COLLAPSIBLE_CONTENT_MIN_SIZE;
    }),
    didInsertElement: function didInsertElement() {
      if (!this.get('readOnly') && this.get('filterType')) {
        var element = this.element.querySelector('input');

        if (element) {
          this.initInputFilter(element, this.get('zeroToBlank'));
        }
      }
    },
    initInputFilter: function initInputFilter(element, zeroToBlank) {
      // see https://stackoverflow.com/questions/469357/html-text-input-allow-only-numeric-input
      // and https://jsfiddle.net/emkey08/zgvtjc51
      var inputFilter = null;

      var inputFilterPositiveInteger = function inputFilterPositiveInteger(value) {
        return /^([1-9]\d*)*$/.test(value);
      };

      var inputFilterPositiveOrZeroInteger = function inputFilterPositiveOrZeroInteger(value) {
        return /^([0-9]\d*)*$/.test(value);
      };

      var inputFilterPercentage = function inputFilterPercentage(value) {
        return /^\d{0,2}$/.test(value);
      };

      var inputFilterInteger = function inputFilterInteger(value) {
        return /^\d*$/.test(value);
      };

      var inputFilterDots = function inputFilterDots(value) {
        return /^\d*[.]?\d*[\d.]*$/.test(value);
      };

      var inputFilterDecimal = function inputFilterDecimal(value) {
        return /^\d*[.,]?\d*$/.test(value);
      };

      var inputFilterRomans = function inputFilterRomans(value) {
        return /^[cdilmvx]*$/i.test(value);
      };

      var inputFilterHyphensAndNumbers = function inputFilterHyphensAndNumbers(value) {
        return /^[\d-]*$/.test(value);
      };

      if (this.get('filterType') === 'numbersOnly') {
        inputFilter = inputFilterInteger;
      } else if (this.get('filterType') === 'numbersDecimal') {
        inputFilter = inputFilterDecimal;
      } else if (this.get('filterType') === 'numbersDots') {
        inputFilter = inputFilterDots;
      } else if (this.get('filterType') === 'romanAndNumbers') {
        inputFilter = function inputFilter(value) {
          return inputFilterInteger(value) || inputFilterRomans(value);
        };
      } else if (this.get('filterType') === 'hyphensAndNumbers') {
        inputFilter = function inputFilter(value) {
          return inputFilterHyphensAndNumbers(value);
        };
      } else if (this.get('filterType') === 'positiveNumbersOnly') {
        inputFilter = inputFilterPositiveInteger;
      } else if (this.get('filterType') === 'positiveNumbersWithZero') {
        inputFilter = inputFilterPositiveOrZeroInteger;
      } else if (this.get('filterType') === 'percentageNumbers') {
        inputFilter = inputFilterPercentage;
      }

      if (inputFilter != null) {
        ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
          element.addEventListener(event, function () {
            if (inputFilter(this.value)) {
              if (zeroToBlank && this.value === '0') {
                this.value = null;
              }

              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
            } else {
              if (this.hasOwnProperty('oldValue')) {
                this.value = this.oldValue;
              }

              if (['number'].indexOf(this.type) < 0 && this.hasOwnProperty('oldSelectionStart') && this.hasOwnProperty('oldSelectionEnd')) {
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
              }
            }
          });
        });
      }
    },
    actions: {
      focusOut: function focusOut() {
        this.sendAction('focusOut');
      }
    }
  });

  _exports.default = _default;
});