define("mvb-ember-components/components/controls/-textarea", ["exports", "mvb-ember-components/templates/components/controls/-textarea"], function (_exports, _textarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEIGHT_MIN = '37'; // approximately one row --> see form-control height (scss)

  var _default = Ember.Component.extend({
    layout: _textarea.default,
    classNames: ['textarea-form-element'],
    classNameBindings: ['resize:resizeable'],
    readonly: false,
    autoresize: false,
    minHeight: Ember.computed('rows', function () {
      var rows = this.get('rows');

      if (!Ember.isEmpty(rows)) {
        return rows * HEIGHT_MIN;
      }

      return HEIGHT_MIN;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.shouldAutoResize();
    },
    actions: {
      focusOut: function focusOut() {
        this.sendAction('focusOut');
        this.shouldAutoResize();
      }
    },
    autoresizeFkt: function autoresizeFkt() {
      var textarea = this.$('.textarea');
      var paddingTop = parseInt(textarea.css('padding-top'));
      var paddingBottom = parseInt(textarea.css('padding-bottom'));
      var minHeight = this.get('minHeight'); // always set the min height first --> needed for shrinking the textarea

      textarea.height(minHeight); // we have to set the overflow to scroll so the correct height calculation works for firefox aswell

      textarea.css('overflow', 'scroll'); // calculate the scroll height and remove the padding top and bottom

      var contentHeight = textarea.prop('scrollHeight') - (paddingBottom + paddingTop);
      textarea.css('height', 'auto');
      textarea.css('overflow', 'hidden'); // set the actual height of the textarea

      textarea.height(contentHeight > 0 ? contentHeight : minHeight);
    },
    shouldAutoResize: function shouldAutoResize() {
      if (this.get('autoresize')) {
        this.autoresizeFkt();
      }
    }
  });

  _exports.default = _default;
});