define("mvb-ember-components/components/tables/-pagination", ["exports", "mvb-ember-components/templates/components/tables/-pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE tables.-pagination:notes
  The pagination control needs 2 parameters of type number
  * currentPage
  * totalPages
  
  On click of an pagination button it triggers the action `onPageSelect(selectedPageNumber)` with the selected _pageNumber_ as the first parameter
  END-FREESTYLE-USAGE*/
  var _default = Ember.Component.extend({
    layout: _pagination.default,
    tagName: 'nav',
    currentPage: 1,
    totalPages: 1,
    maxPaginationLinks: 5,
    actions: {
      selectPage: function selectPage(pageNumber) {
        this.sendAction('onPageSelect', pageNumber);
      }
    },
    maxInnerLinks: Ember.computed('maxPaginationLinks', function () {
      return this.get('maxPaginationLinks') - 2;
    }),
    isOnLeftSide: Ember.computed('currentPage', 'totalPages', 'maxInnerLinks', 'maxPaginationLinks', function () {
      return this.get('currentPage') <= this.get('maxInnerLinks') || this.get('totalPages') <= this.get('maxPaginationLinks');
    }),
    isOnRightSide: Ember.computed('currentPage', 'totalPages', 'maxInnerLinks', 'maxPaginationLinks', function () {
      var _this$getProperties = this.getProperties('currentPage', 'totalPages', 'maxInnerLinks', 'maxPaginationLinks'),
          currentPage = _this$getProperties.currentPage,
          totalPages = _this$getProperties.totalPages,
          maxInnerLinks = _this$getProperties.maxInnerLinks,
          maxPaginationLinks = _this$getProperties.maxPaginationLinks;

      if (!totalPages) {
        return true;
      }

      return totalPages - currentPage < maxInnerLinks || maxPaginationLinks >= totalPages;
    }),
    pageLinks: Ember.computed('currentPage', 'totalPages', 'isOnLeftSide', 'isOnRightSide', 'maxInnerLinks', function () {
      var _this$getProperties2 = this.getProperties('currentPage', 'totalPages', 'maxInnerLinks', 'isOnLeftSide', 'isOnRightSide'),
          currentPage = _this$getProperties2.currentPage,
          totalPages = _this$getProperties2.totalPages,
          maxInnerLinks = _this$getProperties2.maxInnerLinks,
          isOnLeftSide = _this$getProperties2.isOnLeftSide,
          isOnRightSide = _this$getProperties2.isOnRightSide; // if not on side we render an uneven amount of links


      var linksToRender = isOnLeftSide || isOnRightSide ? maxInnerLinks : maxInnerLinks - (maxInnerLinks + 1) % 2; // determine first link

      var pageLink = isOnLeftSide ? 2 : currentPage - Math.floor(linksToRender / 2);

      if (isOnRightSide) {
        pageLink = totalPages - maxInnerLinks;
      } // collect page numbers to render


      var pageLinks = [];

      for (var i = 0; pageLink <= currentPage + maxInnerLinks; pageLink++) {
        if (pageLink > 1 && pageLink < totalPages && pageLinks.length < linksToRender) {
          pageLinks[i++] = pageLink;
        }
      }

      return pageLinks;
    })
  });

  _exports.default = _default;
});