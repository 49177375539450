define("mvb-ember-components/components/controls/-maintitle", ["exports", "mvb-ember-components/templates/components/controls/-maintitle"], function (_exports, _maintitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _maintitle.default,
    actions: {
      onIconClick: function onIconClick() {
        this.sendAction('onIconClick');
      }
    }
  });

  _exports.default = _default;
});