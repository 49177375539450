define("mvb-ember-components/components/-demo", ["exports", "mvb-ember-components/templates/components/-demo"], function (_exports, _demo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _demo.default,
    status: ['all', 'ok', 'warning', 'error', 'pending', 'some pretty long long long long long option'],
    now: new Date()
  });

  _exports.default = _default;
});