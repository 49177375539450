define("mvb-ember-components/helpers/lt-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ltEq = ltEq;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.lt-eq:notes
  Less than or equal to returns true when the first number is equal to or lower than the second number.
  END-FREESTYLE-USAGE */
  function ltEq(params
  /*, hash*/
  ) {
    return params[0] <= params[1];
  }

  var _default = Ember.Helper.helper(ltEq);

  _exports.default = _default;
});