define("mvb-ember-components/initializers/component-attr-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var comp = {
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('attrsHash')) {
        var hash = this.get('attrsHash');
        Object.keys(hash).forEach(function (key) {
          _this.set(key, hash[key]);
        });
      }
    }
  };

  function initialize(application) {
    window.EmberAppInstance = application;
    Ember.Component.reopen(comp);
  }

  var _default = {
    name: 'component-attr-hash',
    initialize: initialize
  };
  _exports.default = _default;
});