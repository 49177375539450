define("mvb-ember-components/helpers/m", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MessageHelper = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params, hash) {
      var key = params[0];

      if (hash) {
        if (hash.hash) {
          hash = Ember.copy(hash.hash);
        } else {
          hash = Ember.copy(hash);
        }
      } else {
        hash = {};
      }

      for (var i = 1; i < params.length; i++) {
        hash[i - 1] = params[i];
      }

      var intl = this.get('intl');

      if (hash.keyNotFound == null || intl.exists(key)) {
        return intl.t(key, hash);
      } else {
        return hash.keyNotFound;
      }
    }
  });
  var _default = MessageHelper;
  _exports.default = _default;
});