define("mvb-ember-components/helpers/not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.not = not;
  _exports.default = void 0;

  function not(params
  /*, hash*/
  ) {
    return !params[0];
  }

  var _default = Ember.Helper.helper(not);

  _exports.default = _default;
});