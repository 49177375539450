define("mvb-ember-components/components/modals/-modal-wrapper-for-service", ["exports", "mvb-ember-components/templates/components/modals/-modal-wrapper-for-service"], function (_exports, _modalWrapperForService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _modalWrapperForService.default,
    modal: Ember.inject.service(),
    didRender: function didRender() {
      window.$('.ember-modal-dialog.beforeFadeIn').fadeTo(400, 1);
      window.$('.ember-modal-dialog.beforeFadeIn').removeClass('beforeFadeIn').addClass('isVisible');
    },
    actions: {
      closeModal: function closeModal() {
        var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

        if (mode === 'cancel') {
          // check if there is a cancel action for the modal component, if so let it run on click on x in modal
          if (typeof this.get('modal.componentData.cancelAction') === 'function') {
            this.get('modal.componentData.cancelAction')();
          }
        }

        var that = this;
        window.$('.ember-modal-dialog.isVisible').fadeTo(400, 0, function () {
          that.get('modal').close();
        });
      }
    }
  });

  _exports.default = _default;
});