define('ember-block-slots/mixins/slots', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;

  /**
   * A convenience mixin that provides the necessary interface
   * to activate and deactivate a yield-slot/block-slot pairing
   */
  exports['default'] = Mixin.create({
    _slots: computed(function () {
      return _ember['default'].A();
    }),

    _activateSlot: function _activateSlot(name) {
      this.get('_slots').addObject(name);
    },

    _deactivateSlot: function _deactivateSlot(name) {
      this.get('_slots').removeObject(name);
    },
    _isRegistered: function _isRegistered(name) {
      return this.get('_slots').includes(name);
    }
  });
});