define("mvb-ember-components/components/layout/-loading-overlay", ["exports", "mvb-ember-components/templates/components/layout/-loading-overlay"], function (_exports, _loadingOverlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _loadingOverlay.default
  });

  _exports.default = _default;
});