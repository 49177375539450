define("mvb-ember-components/helpers/is-gt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isGt = isGt;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.is-gt:notes
  Returns true if the first params is greater than the second one, false if otherwiese.
  END-FREESTYLE-USAGE */
  function isGt(params
  /*, hash*/
  ) {
    return params[0] > params[1];
  }

  var _default = Ember.Helper.helper(isGt);

  _exports.default = _default;
});