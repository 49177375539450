define("mvb-ember-components/helpers/is-neq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNotEq = isNotEq;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.is-neq:notes
  The is not equal function returns false if both params are equal otherwise returns true.
  END-FREESTYLE-USAGE */
  function isNotEq(params
  /*, hash*/
  ) {
    // should do it without type safety check
    return !(params[0] == params[1]); // jshint ignore:line
  }

  var _default = Ember.Helper.helper(isNotEq);

  _exports.default = _default;
});