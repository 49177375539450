define("mvb-ember-components/components/controls/-select", ["exports", "mvb-ember-components/templates/components/controls/-select"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _select.default,
    intl: Ember.inject.service(),
    isMultiSelect: false,
    sortProperty: null,
    sortedOptions: Ember.computed('options', 'sortProperty', function () {
      var _this$getProperties = this.getProperties('sortProperty', 'options'),
          sortProperty = _this$getProperties.sortProperty,
          options = _this$getProperties.options;

      if (!sortProperty || !options) {
        return options;
      }

      var sortPropertyArray = this.get('sortProperty').split(',');
      return options.sort(function (a, b) {
        for (var i = 0; i < sortPropertyArray.length; i++) {
          var key = sortPropertyArray[i];
          var propA = Ember.get(a, key);
          var propB = Ember.get(b, key);

          if (propA === null || propB === null) {
            if (propA === propB) {
              continue;
            } else if (propA !== null) {
              return -1;
            } else if (propB !== null) {
              return 1;
            }
          } // return 1 or -1 else continue to the next sortKey


          var compareValue = Ember.compare(propA, propB);

          if (compareValue) {
            return compareValue;
          }
        }

        return 0;
      });
    }),
    searchEnabled: Ember.computed('isMultiSelect', function () {
      return this.get('isMultiSelect') ? true : false;
    }),
    placeholder: Ember.computed('isMultiSelect', function () {
      return this.get('isMultiSelect') ? this.get('intl').t('label.selection.placeholder.multiple') : this.get('intl').t('label.selection.placeholder');
    }),
    noMatchesMessage: Ember.computed(function () {
      return this.get('intl').t('label.selection.noMatchesFound');
    })
  });

  _exports.default = _default;
});