define("mvb-ember-components/helpers/separator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.separator = separator;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.separator:notes
   Adds thousands separator to the given value
  
   * params[0]: value
   * params[1]: separator, defaults to '.'
   END-FREESTYLE-USAGE */
  function separator(params
  /*, hash*/
  ) {
    var separator = params[1] ? params[1] : '.';
    var position = 3;
    var valueAsString = '' + params[0]; // so we have a string here

    var decimalPlace = valueAsString.lastIndexOf(',');
    var value = decimalPlace > -1 ? valueAsString.substr(0, decimalPlace) : valueAsString;
    var decimal = decimalPlace > -1 ? valueAsString.substr(decimalPlace, 3) : '';

    if (value.length > position) {
      var modulo = value.length % 3;
      var returnValue = modulo > 0 ? value.substring(0, modulo) : '';

      for (var i = 0; i < Math.floor(value.length / position); i++) {
        if (modulo === 0 && i === 0) {
          returnValue += value.substring(modulo + 3 * i, modulo + 3 * i + 3);
        } else {
          returnValue += separator + value.substring(modulo + 3 * i, modulo + 3 * i + 3);
        }
      }

      return returnValue + decimal;
    }

    return value;
  }

  var _default = Ember.Helper.helper(separator);

  _exports.default = _default;
});