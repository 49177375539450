define("mvb-ember-components/helpers/to-upper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toUpper = toUpper;
  _exports.default = void 0;

  /* BEGIN-FREESTYLE-USAGE helper.to-upper:notes
   Transforms any String to upper case.
   END-FREESTYLE-USAGE */
  function toUpper(params
  /*, hash*/
  ) {
    if (params[0] && typeof params[0] === 'string') {
      return params[0].toUpperCase();
    }

    return '';
  }

  var _default = Ember.Helper.helper(toUpper);

  _exports.default = _default;
});