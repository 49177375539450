define("mvb-ember-components/components/controls/-bs-tab-pane", ["exports", "ember-bootstrap/components/base/bs-tab/pane", "mvb-ember-components/templates/components/controls/-bs-tab-pane"], function (_exports, _pane, _bsTabPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extends standard bs-tab-pane with the ability to only render a tab's content if it is active
   */
  var _default = _pane.default.extend({
    layout: _bsTabPane.default,
    renderIfHidden: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('renderContent', this.get('renderIfHidden'));
    },
    renderContentObserver: Ember.observer('active', 'renderIfHidden', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        _this.set('renderContent', _this.get('renderIfHidden') ? true : _this.get('active'));
      });
    })
  });

  _exports.default = _default;
});