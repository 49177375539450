define("mvb-ember-components/components/controls/-dropdown", ["exports", "mvb-ember-components/templates/components/controls/-dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dropdown.default,
    router: Ember.inject.service('-routing'),

    /* BEGIN-FREESTYLE-USAGE controls.-dropdown */
    captionRoute: '',
    dropDirection: 'dropdown',
    dropIconClass: 'halflings halflings-triangle-bottom dropdown-arrow',
    toggleDropDirection: function toggleDropDirection() {
      var viewPortHeight = window.innerHeight;
      var dropdownPosition = this.$()[0].getBoundingClientRect();
      this.set('dropDirection', viewPortHeight - dropdownPosition.top < 150 ? 'dropup' : 'dropdown');
      var onclick = this.get('onclick');

      if (onclick) {
        this.sendAction('onclick');
      }
    },
    clickCaption: function clickCaption(event) {
      var captionRoute = this.get('captionRoute');

      if (captionRoute) {
        event.stopPropagation();
        var routeParam = [];
        var captionRouteParams = this.get('captionRouteParams');

        if (captionRouteParams) {
          if (Ember.isArray(captionRouteParams)) {
            routeParam = captionRouteParams;
          } else {
            routeParam.push(captionRouteParams);
          }
        }

        var router = this.get('router');
        router.transitionTo(captionRoute, routeParam);
      }
    },
    actions: {
      clickCaption: function clickCaption(event) {
        this.clickCaption(event);
      },
      toggleDropDirection: function toggleDropDirection() {
        this.toggleDropDirection();
      }
    }
    /* END-FREESTYLE-USAGE */

  });

  _exports.default = _default;
});